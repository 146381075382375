import { useUserStore } from '@/store/modules/user';

interface IReportData {
    eventName: string;
    dict?: any;
    header?: any;
    before: any;
    after: any;
    operatePath: string;
    gid: string | number;
}

/**
 * 获取表单前后变化
 * @param {*} dict {key: {label: 'xxx', options?:[{xxx:xxx}], label: 'xxx', value: 'xxx'}}
 * @param {*} before {key: xxx}
 * @param {*} after {key: xxx}
 * @returns {beforeRet: [], afterRet: []}
 */
export function getFormChange(dict:any, before:any, after:any) {
    const beforeRet = [];
    const afterRet = [];
    const getLabelVal = (options:any, key:any, value:any, val:any) => {
        if (options && options.length) {
            for (let i = 0; i < options.length; i++) {
                const current = options[i];
                if (current[value] === val) {
                    return current[key];
                }
            }
        }
    };
    for (const k in before) {
        if (JSON.stringify(before[k]) !== JSON.stringify(after[k])) {
            const { options, label, value, params } = dict[k];
            const name = dict[k].name;
            if (params) {
                for (let i = 0; i < params.length; i++) {
                    if (before[k][params[i].key] !== after[k][params[i].key]) {
                        beforeRet.push({
                            name: params[i].label,
                            value: String(before[k][params[i].key]),
                        });
                        afterRet.push({
                            name: params[i].label,
                            value: String(after[k][params[i].key]),
                        });
                    }
                }
            } else {
                beforeRet.push({
                    name,
                    value: options
                        ? getLabelVal(options, label, value, before[k])
                        : typeof before[k] === 'object'
                            ? JSON.stringify(before[k])
                            : String(before[k]),
                });
                afterRet.push({
                    name,
                    value: options
                        ? getLabelVal(options, label, value, after[k])
                        : typeof after[k] === 'object'
                            ? JSON.stringify(after[k])
                            : String(after[k]),
                });
            }
        }
    }
    console.log({ beforeRet });
    console.log({ afterRet });
    return {
        beforeRet,
        afterRet,
    };
}

// 处理数据

// 处理单个数据
// const handleSingleData = (header: string, before:any, after:any) => {
//
// };

export const customDataReport = ({ eventName, dict, header, before, after, operatePath, gid }: IReportData) => {
    console.log('header', header);
    console.log('dict', dict);
    console.log('after===>', after);
    console.log('before===>', before);
    let beforeRet:any = null;
    let afterRet:any =null;
    if (!header) {
        const formChange = getFormChange(dict, before, after);
        beforeRet = formChange.beforeRet;
        afterRet = formChange.afterRet;
    }
    let params = {};
    if (header) {
        params = {
            table_before: before,
            table_after: after,
            table_header: header
        };
    }

    console.log('table_params', params);
    console.log('beforeRet', beforeRet);
    console.log('afterRet', afterRet);
    //@ts-ignore
    window.staticFn.customEvent(eventName,
        {
            game_id: gid,
            operate_path: operatePath,
            original_params: beforeRet,
            current_params: afterRet,
            table_params: params,
            gmt_update: new Date().getTime(),
            uid_update: useUserStore().userInfo.Id,
            name_update: useUserStore().userInfo.UserName,
        });
};
